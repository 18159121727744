import React, {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {Button, CloseIcon, ExclamationTriangleIcon} from 'components';
import {useCountdown} from 'hooks/useCountdown';
import {useTranslation} from 'react-i18next';

import {Dialog, Transition} from '@headlessui/react';

const CountDownWarning: FC<{
  appointmentTime: string;
  countdownDuration?: number;
}> = ({appointmentTime, countdownDuration}) => {
  const {t} = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const {hours, minutes, seconds, isCountingDown} = useCountdown(
    appointmentTime,
    countdownDuration,
  );

  useEffect(() => {
    setShow(isCountingDown);
  }, [isCountingDown]);

  const handleClose = useCallback(() => setShow(false), []);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-999" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 backdrop-blur-3xl" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all">
                <div className="w-full min-h-[100px] relative bottom-0 left-0 rounded-md flex flex-col p-6 pt-12">
                  <button
                    className="absolute right-5 top-3.5 border border-gray-700 rounded-full"
                    onClick={handleClose}
                  >
                    <CloseIcon
                      strokeColor="text-gray-700 group-hover:text-white"
                      strokeWidth={40}
                      classes="w-5 h-5"
                    />
                  </button>
                  <div className="flex justify-center items-center mb-1">
                    <ExclamationTriangleIcon
                      width={50}
                      height={50}
                      aria-hidden="true"
                    />
                  </div>
                  <h4 className="font-inter text-base font-bold uppercase text-center text-gray-800 mb-1">
                    {t('timeRemaining', 'Time Remaining')}
                  </h4>
                  <div className="flex items-center justify-center gap-2 border border-gray-400 rounded-lg p-2.5">
                    {hours !== '00' && (
                      <div className="flex flex-col justify-center items-center font-inter text-lg font-semibold">
                        {hours}
                        <span className="block text-xs capitalize">
                          {t('hours', 'Hours')}
                        </span>
                      </div>
                    )}
                    <div className="flex flex-col justify-center items-center font-inter text-lg font-semibold">
                      {minutes}
                      <span className="block text-xs capitalize">
                        {t('minutes', 'Minutes')}
                      </span>
                    </div>
                    <div className="flex flex-col justify-center items-center font-inter text-lg font-semibold">
                      {seconds}
                      <span className="block text-xs capitalize">
                        {t('seconds', 'Seconds')}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center items-start gap-2 mt-3">
                    <p className="font-inter text-sm font-medium leading-snug text-center">
                      {t('remain5MinsCall', {countdownDuration})}
                    </p>
                  </div>
                  <section className="pt-8 text-center flex justify-center">
                    <Button
                      type="button"
                      className="flex justify-center items-center w-full h-10 md:w-36 p-4 shadow-sm capitalize"
                      outline
                      onClick={handleClose}
                      borderRadius="full"
                    >
                      {t('close', 'Close')}
                    </Button>
                    <span className="px-1" />
                    <Button
                      type="button"
                      className="flex justify-center items-center w-full h-10 md:w-36 p-4 shadow-sm capitalize"
                      btnType="primary"
                      onClick={() => {}}
                      borderRadius="full"
                    >
                      {t('continue', 'Continue')}
                    </Button>
                  </section>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CountDownWarning;
